
import Modal from "react-modal";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import { repayCustomStyles } from "../../styles/theme";
import React, { useEffect } from "react";
import { isRepayPopupOpenState } from "../../recoil/atoms";
import { RepayModalBox, RoundButton, RepayPopupBtns } from "./style";
const parse = require("html-react-parser");

Modal.setAppElement("#root");

function RepayPopup({ closeBtnText = '닫기', onClickBtnText = '확인', content, onClick, onCloseClick }: { closeBtnText?: string, onClickBtnText?: string, content: React.ReactNode, onClick?: Function, onCloseClick?: Function }) {
    const [isOpen, setIsOpen] = useRecoilState(isRepayPopupOpenState);

    function closeModal() {
        setIsOpen(false);
    }
    useEffect(() => {
        console.log(`isOpen: ${isOpen}`);
    }, [isOpen])

    ReactModal.defaultStyles.overlay!.backgroundColor = "rgba( 0, 0, 0, 0)";
    return (
        <Modal isOpen={isOpen} style={repayCustomStyles} contentLabel="EvPlug popup" shouldCloseOnOverlayClick={true}>
            <RepayModalBox width="640px">
                <div
                    style={{
                        'width': "100%",
                        'display': 'flex'
                    }}
                >
                    <div className="w-full" id="detailContent">
                        {typeof content === 'string' ?
                            <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
                    </div>
                </div>
                <RepayPopupBtns>
                    {
                        onClick == null ?
                            <RoundButton
                                fill={true}
                                width="160px"
                                onClick={() => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                    closeModal();
                                }}
                            >확인</RoundButton> :
                            <>
                                <RoundButton
                                    fill={false}
                                    width="160px"
                                    onClick={async () => {
                                        if (onCloseClick != null) {
                                            await onCloseClick();
                                        }
                                        closeModal();
                                    }}
                                >{closeBtnText}</RoundButton>
                                <RoundButton fill={true} width="160px" onClick={() => onClick()}>{onClickBtnText}</RoundButton>
                            </>
                    }
                </RepayPopupBtns>
            </RepayModalBox>
        </Modal >
    );
}


export default RepayPopup;
