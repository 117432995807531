import styled, { css, keyframes } from "styled-components";
import { inlineWidth, labelBgColor, listBorderColor, mobileChangeWidth, noticeFontColor, orangeBtnGradient, primaryColor, searchBorderColor, stipulationBgColor } from "../../styles/theme";

const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  border-top: 2px solid black;
`;

const ChargingListHead = styled.div`
  background: #f7f7f7;
  border-color: hsla(0, 0%, 7%, 0.8);
  border-width: 2px 0 0 1px;
  border-bottom: 1px solid ${listBorderColor};
  border-top: 2px solid #000;
  font-size: "16px"; //15px;
  font-weight: 500;
  padding: 13px 10px;
  width: 100%;
  border-right: 0px;
  border-left: 0px;
  display: flex;
  min-width: 960px;
`;

const ChargingListContent = styled.div`
  font-weight: 400;
  color: ${noticeFontColor};
  padding: 10px;
  font-size: "16px";
  border-bottom: 1px solid ${searchBorderColor};
  width: 100%;
  display: flex;
  min-width: 960px;
  :hover {
    background-color: ${labelBgColor};
  }
  .point_reduce{
    color:#ff0000;
  }
`;
const ScrollDiv = styled.div`
  overflow-x: auto;
  min-height: 500px;
  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

const HistoryTabList = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${searchBorderColor};
  li{
    border-left:1px solid ${searchBorderColor};
  }
  li:last-child{
    border-right:1px solid ${searchBorderColor};
  }
  .historyTabButton{
    display: inline-block;
    width: 128px;
    height: 40px;
    padding-top: 1px;
    background-color: #f7f7f7;
    border-top: 1px solid ${searchBorderColor};
    text-align: center;
    line-height: 39px;
    &.active{
      padding-top: 0px;
      background-color: #ffffff;
      border-top: 2px solid #595959;
    }
  }
`

const HistoryTabButton = styled.button`
  width: 128px;
  height: 40px;
  padding-top: 1px;
  background-color: #f7f7f7;
  border-top: 1px solid ${searchBorderColor};
  &.active{
    padding-top: 0px;
    background-color: #ffffff;
    border-top: 2px solid #595959;
  }
`

const ProgressTexts = styled.div`
    margin-top: 20px;
    width:100%;
    display: flex;
    justify-content:space-between;
`

const heartbeat = keyframes`
  0% {
    transform: scale(0.997);
  }
  50% {
    transform: scale(1.002);
  }
  100% {
    transform: scale(0.997);
  }
`

interface ProgressBarProps {
  heartbeatBoolean: boolean
}

const ProgressBar = styled.div<ProgressBarProps>`
    width: 100%;
    height: 30px;
    background-color: #dedede;
    border-radius:12px;
    font-weight: 600;
    font-size: .8rem;
    margin-top: 10px;
    overflow: hidden;
    animation: ${({ heartbeatBoolean }) => heartbeatBoolean ? css`${heartbeat} 1s infinite` : 'none'};
`;

interface ITest {
  width: number,
}

const ProgressInner = styled.div<ITest>`
    width: ${(props) => props.width}%; 
    height: 30px;
    padding: 0;
    text-align: center;
    background: ${orangeBtnGradient};
    color: #111;
`;

const RemainValueBox = styled.div`
    margin-top:16px;
    align-self: flex-start;
    position: relative;
    left: 0;
    div.triangle{
        font-size:0;
        position: absolute;
        top:-12px;
        left:10px;
        width: 0;
        height: 0;
        border-bottom: 6px solid #333333;
        border-top: 6px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }
    span{
        display: inline-block;
        padding: 4px 8px;
        border-radius: 8px;
        background-color: #333333;
        font-weight: 500;
        color:#ffffff;
    }
`

interface StyledRankText {
  marginLeft?: string
}

const StyledRankText = styled.span<StyledRankText>`
  color: ${primaryColor};
`

const StyledTotal = styled.div`
  font-weight: 400;
  color: ${noticeFontColor};
  
  font-size: "16px";
  border: 1px solid ${searchBorderColor};
  border-left:0;
  border-right:0;
  width: 100%;
  display: flex;
  div{
    padding: 10px;
  }
  .bg-gray-112{
    background-color: #f7f7f7;
  }
`

const NotifiContainer = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 100%;
`

const NotifiContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${inlineWidth};
  margin: 0px auto;
  position: relative;
  box-shadow: 0px 4px 12px 0px #00000040;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
    align-items: end;
    gap: 24px;
  }
`

const NotifiInfo = styled.div`
  display: inline-block;
  padding: 6px 14px;
  margin-top: 12px;
  background-color: rgba(255, 164, 164, 0.12);
  border-radius: 4px;
`

const TextRedStrong = styled.span`
  font-weight: 700;
  color: #EE5555;
`

const TextStrong = styled.span`
  font-weight: 700;
`


export { InfoBox, ChargingListHead, ScrollDiv, ChargingListContent, HistoryTabList, HistoryTabButton, ProgressTexts, ProgressBar, ProgressInner, RemainValueBox, StyledRankText, StyledTotal, NotifiContainer, NotifiContent, NotifiInfo, TextRedStrong, TextStrong };
