import styled from "styled-components";
import { modalBorderColor } from "../../styles/theme";

interface ModalBoxProps {
  width?: string;
  height?: string;
}
const ModalBox = styled.div<ModalBoxProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "100%"};

  @media (max-width: 600px){
    width: 300px;
  }
`;

interface ModalContentProps {
  padding?: string;
  height?: string;
  textAlign?: string;
  alignItems?: string;
  justifyContent?: string;
}

const ModalContent = styled.div<ModalContentProps>`
  width: 100%;
  height: ${(props) => props.height ?? "70%"};
  padding: ${(props) => props.padding ?? "12px"}; //12px;
  margin: 0px auto;
  text-align: ${(props) => props.textAlign ?? "center"}; //center;
  display: flex;
  align-items: ${(props) => props.alignItems ?? "center"}; //center;
  justify-content: ${(props) => props.justifyContent ?? "center"}; //center;
  flex-direction: column;
`;

interface ModalContentProps {
  padding?: string;
  height?: string;
}

const ModalBorderContent = styled.div`
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${modalBorderColor};
  border-bottom: 1px solid ${modalBorderColor};
  display: flex;
  align-items: center;
`;

const PopupBtns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(30,35,44,1);
`;

interface RepayModalBoxProps {
  width?: string;
  height?: string;
}
const RepayModalBox = styled.div<RepayModalBoxProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "100%"};
  padding: 50px 30px 30px 30px;

  @media (max-width: 600px){
    width: 320px;
  }
`;

const RepayPopupBtns = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 40px;
  gap: 12px;
`

interface RoundButtonProps {
  width?: string;
  height?: string;
  fill: boolean;
}

const RoundButton = styled.button<RoundButtonProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "46px"};
  border: ${(props) => props.fill ? "none" : "1px solid #ff8116"};
  background-color: ${(props) => props.fill ? "#ff8116" : "none"};
  color: ${(props) => props.fill ? "#ffffff" : "#ff8116"};
  border-radius: 46px;
  font-size: 16px;
  font-weight: 700;
`



export { ModalBox, ModalContent, ModalBorderContent, PopupBtns, RepayModalBox, RepayPopupBtns, RoundButton };
